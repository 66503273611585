import React from 'react';
import PropTypes from 'prop-types';
import {
  getIsTagLabelEnabled,
  getTagAlignment,
  getTagVerticalSpacing,
  getTagHorizontalSpacing,
  getTagOrder,
  getTagShowPostCount,
  getNumberOfTags,
} from '../../selectors/app-settings-selectors.js';
import { getTagTextAlignment, sortTags } from '../../services/tag-helpers';
import { connect } from '../runtime-context';
import Tag from './tag';
import TagsEmptyState from './tags-empty-state';
import styles from './tags.scss';

export class PostTags extends React.Component {
  render() {
    const {
      tags,
      isTagLabelEnabled,
      tagAlignment,
      tagVerticalSpacing,
      tagHorizontalSpacing,
      isPostPageTags,
      showPostCount,
      numberOfTags,
      tagOrder,
      shouldSortTags,
      postId,
      translations,
    } = this.props;
    if (!tags || !tags.length) {
      if (isPostPageTags) {
        return null;
      }

      return <TagsEmptyState emptyState={translations.emptyState} />;
    }
    const containerStyles = {
      textAlign: getTagTextAlignment(tagAlignment),
    };

    const tagWrapperStyles = {
      marginLeft: -tagHorizontalSpacing,
      marginTop: -tagVerticalSpacing,
    };

    const sortedTags = shouldSortTags ? sortTags(tags, tagOrder) : tags;
    const preparedTags = isPostPageTags
      ? sortedTags
      : sortedTags.slice(0, numberOfTags);

    return (
      <div
        style={containerStyles}
        className={isPostPageTags && styles.postPageTagContainer}
      >
        {isTagLabelEnabled && (
          <p className={styles.tagLabel}>{translations.tagsLabel}</p>
        )}
        <nav
          aria-label="tags"
          className={styles.navigation}
          style={tagWrapperStyles}
        >
          <ul>
            {preparedTags.map(({ id, slug, label }) => (
              <Tag
                postId={postId}
                key={id}
                id={id}
                slug={slug}
                label={label}
                a11yText={translations.tags[id].a11yText}
                postCount={
                  showPostCount ? translations.tags[id].postCount : null
                }
              />
            ))}
          </ul>
        </nav>
      </div>
    );
  }
}

PostTags.propTypes = {
  tags: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string.isRequired,
      label: PropTypes.string.isRequired,
      slug: PropTypes.string.isRequired,
    }),
  ),
  isTagLabelEnabled: PropTypes.bool.isRequired,
  tagAlignment: PropTypes.number.isRequired,
  tagVerticalSpacing: PropTypes.number.isRequired,
  tagHorizontalSpacing: PropTypes.number.isRequired,
  isPostPageTags: PropTypes.bool,
  shouldSortTags: PropTypes.bool,
  postId: PropTypes.string,
  postLegacyId: PropTypes.string,
  translations: PropTypes.object.isRequired,
};

PostTags.defaultProps = {
  shouldSortTags: true,
};

const mapRuntimeToProps = (state, { isPostPageTags }) => ({
  isTagLabelEnabled: getIsTagLabelEnabled(state),
  tagAlignment: getTagAlignment(state),
  tagVerticalSpacing: getTagVerticalSpacing(state),
  tagHorizontalSpacing: getTagHorizontalSpacing(state),
  tagOrder: getTagOrder(state),
  showPostCount: getTagShowPostCount(state) && !isPostPageTags,
  numberOfTags: getNumberOfTags(state),
});

export default connect(mapRuntimeToProps)(PostTags);
